//
//
//
//
//
//
//
//

import Visa from "./visa.svg?inline";
import Mastercard from "./mastercard.svg?inline";
import Mir from "./mir.svg?inline";
import Unionpay from "./unionpay.svg?inline";
export default {
  components: {
    Visa,
    Mastercard,
    Mir,
    Unionpay
  }
};